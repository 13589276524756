export const MAX_REFERRALS_PER_PAGE = 25;

export const REFERRAL_CLAIM_OUTCOMES = {
  available: 'available',
  alreadyClaimed: 'alreadyClaimed',
  teamClaimed: 'teamClaimed',
  ineligible: 'ineligible'
};

export default {};
