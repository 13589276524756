import * as Sentry from '@sentry/nextjs';

import { ReactNode } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { PropertyBlockedModal, PropertyEligibilityModal } from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

import { useModal } from '@hl-portals/hooks';

import {
  AutomatedBbysEligibilityCheckArgs,
  AutomatedBbysPropertyEligibilityBlockers,
  AutomatedBbysPropertyEligibilityChecks,
  BlockerWarning,
  PropertyEligibilityChecksResponse,
  checkAutomatedPropertyEligibility,
} from './automated-property_eligibity-lender';

export type EligibilityCheck = keyof AutomatedBbysPropertyEligibilityChecks;
export type EligibilityBlocker = keyof AutomatedBbysPropertyEligibilityBlockers;

export const AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS: Record<
  keyof AutomatedBbysPropertyEligibilityChecks,
  string
> = {
  lot_size_check: "The property's lot size is more than 5 acres.",
  property_type_check:
    'The property is not a single-family home, townhome, or condo.',
  room_count_check:
    'The property does not have at least one bedroom and one bathroom.',
  zoning_description_check: 'The property is not zoned for residential.',
  property_value_check:
    'The property value is valued at over $2.5 million, which might limit the amount of equity we can access.',
  ltv_check:
    'The client might not have enough equity in the departing residence.',
};

export const AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_BLOCKERS_LABELS: Record<
  keyof AutomatedBbysPropertyEligibilityBlockers,
  string | ReactNode
> = {
  lead_exists: (
    <BlockerWarning
      title="Property Already Submitted"
      description=" You’ve already submitted this property for Buy Before You Sell. Please check your email for the confirmation and next steps."
    />
  ),
};

export const USE_AUTOMATED_PROPERTY_ELIGIBILITY_QUERY_KEY =
  'USE_AUTOMATED_PROPERTY_ELIGIBILITY_QUERY_KEY';

export const useAutomatedBbysEligibilityCheck = (
  options: UseMutationOptions<
    PropertyEligibilityChecksResponse,
    unknown,
    AutomatedBbysEligibilityCheckArgs | undefined,
    unknown
  > & { onClose?: () => void }
) => {
  const { openModal, closeModal } = useModal();

  const close = () => {
    options.onClose ? options.onClose() : null;
    closeModal();
  };

  return useMutation({
    mutationFn: checkAutomatedPropertyEligibility,
    ...options,
    onSuccess: (result) => {
      const issues = Object.keys(result.eligibility_checks || {})
        .filter((k) => !result.eligibility_checks[k as EligibilityCheck])
        .map(
          (issue) =>
            AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS[
              issue as EligibilityCheck
            ]
        );

      const blockers = Object.keys(result.eligibility_blockers || {})
        .filter((k) => result.eligibility_blockers[k as EligibilityBlocker])
        .map(
          (issue) =>
            AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_BLOCKERS_LABELS[
              issue as EligibilityBlocker
            ]
        );

      if (config.isProduction && blockers.length) {
        openModal(<PropertyBlockedModal items={blockers} onContinue={close} />);
        return;
      } else if (issues.length) {
        openModal(
          <PropertyEligibilityModal
            items={issues}
            onContinue={() =>
              options.onSuccess
                ? options.onSuccess(result, undefined, {})
                : null
            }
            onCancel={close}
          />
        );
        return;
      }

      if (options?.onSuccess) {
        options.onSuccess(result, undefined, {});
      }
    },
    onError: (error) => {
      toast('There was an error', { type: 'error' });
      Sentry.captureException(error);

      if (options?.onError) {
        options.onError(error, undefined, {});
      }
    },
  });
};
