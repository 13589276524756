import { PropsWithChildren } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

import { Box, BoxTypes } from '../Box';

export type FormProps<T extends FieldValues> = {
  onSubmit: SubmitHandler<T>;
  methods: UseFormReturn<T, any>;
} & BoxTypes;

export function Form<T extends FieldValues>({
  children,
  onSubmit,
  methods,
  ...props
}: PropsWithChildren<FormProps<T>>) {
  return (
    <Box width="100%" {...props}>
      <FormProvider {...methods}>
        <form
          style={{ width: '100%' }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {children}
        </form>
      </FormProvider>
    </Box>
  );
}
