import { REFERRAL_CLAIM_OUTCOMES } from '@hl-portals/constants';

const CLAIMED_STAGES = [
  'agent_left_vm',
  'connected',
  'meeting_scheduled',
  'met_in_person',
];

function isWarmTransferEligible(stage: string, warmTransferPin: number | null): boolean {
  return !!warmTransferPin && (stage === 'quiz_match' || stage === 'warm_transfer_match');
}

export const getClaimEligibility = (
  stage: string,
  claimCallSid: string | null,
  warmTransferPin: number | null = null,
  warmTransferPinState: string | null = null
): string => {
  if (stage === 'failed') {
    return REFERRAL_CLAIM_OUTCOMES.alreadyClaimed;
  }

  if (CLAIMED_STAGES.includes(stage)) {
    return REFERRAL_CLAIM_OUTCOMES.teamClaimed;
  }

  const eligibleForWarmTransfer = isWarmTransferEligible(stage, warmTransferPin);

  if (eligibleForWarmTransfer && warmTransferPinState && warmTransferPinState !== 'active') {
    return REFERRAL_CLAIM_OUTCOMES.alreadyClaimed;
  }

  if (!claimCallSid && (stage === 'introduced' || eligibleForWarmTransfer)) {
    return REFERRAL_CLAIM_OUTCOMES.available;
  }

  return REFERRAL_CLAIM_OUTCOMES.ineligible;
};
