import { sizing, spacing, typography } from '@mui/system';
import styled, { css } from 'styled-components';

import { ReactNode } from 'react';

import { paragraphHelper } from '@hl-portals/helpers';

import { BoxTypes } from '../Box';

export type LabelVariant = 'new-green' | 'new-blue' | 'updated' | 'normal';

type StatusLabelProps = {
  children: ReactNode;
  variant: LabelVariant;
} & BoxTypes;

const StyledStatusLabel = styled.div<StatusLabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.3;
  ${({ theme, variant }) => css`
    ${paragraphHelper({ theme, type: 'text-xsmall-bold' })}
    padding: 3px 5px;
    border-radius: 4px;
    ${variant === 'new-green' &&
    `
      color: ${theme.colors.confirm};
      background-color: ${theme.colors.confirm}26;
    `};
    ${variant === 'new-blue' &&
    `
      color: ${theme.colors.electricBlue};
      background-color: ${theme.colors.white};
    `};
    ${variant === 'updated' &&
    `
      color: ${theme.colors.mediumBlue};
      background-color: ${theme.colors.mediumBlue}26;
    `}
    ${variant === 'normal' &&
    `
      color: ${theme.colors.coolGray1};
      background-color: ${theme.colors.coolGray6};
    `}
  `}
  strong {
    font-weight: 600;
  }
  ${sizing};
  ${spacing};
  ${typography};
`;

const StatusLabel = ({
  variant,
  children,
  ...otherProps
}: StatusLabelProps): React.ReactElement => (
  <StyledStatusLabel variant={variant} {...otherProps}>
    {children}
  </StyledStatusLabel>
);

export default StatusLabel;
