import * as Sentry from '@sentry/nextjs';

import { PUBLIC_EVENT_PATH } from '@hl-portals/constants';

export const usePublicEvent = () => {
  const recordPublicEvent = (body?: any) => {
    return fetch(PUBLIC_EVENT_PATH, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: body ? JSON.stringify(body) : null,
    }).catch((error) => {
      Sentry.captureException(error);
    });
  };

  return { recordPublicEvent };
};
