import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// ==========================================================================
// useMultiStep

export type MultiStepReturnType = {
  steps: string[];
  visitedSteps: string[];
  remainingSteps: string[];
  currentStep: string;
  currentStepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  percentProgress: number;
  isRemainingStep: (step: string) => boolean;
  isPastStep: (step: string) => boolean;
  isCurrentStep: (step: string) => boolean;
  isVisitedStep: (step: string) => boolean;
  unregisterStep: (stepToRemove: string) => void;
  registerStep: (step: string) => void;
  getNextStep: (step: string) => string | undefined;
  getPrevStep: (step: string) => string | undefined;
  goNext: () => void;
  goBack: () => void;
  goToStep: (step: string) => void;
};

type MiddlewareFn = (multiStep: MultiStepReturnType) => void;
type MiddlewareObj = { fn: MiddlewareFn; steps?: string[]; skip?: string[] };
type Middleware = MiddlewareFn | MiddlewareObj;

export type UseMultiStepOptions = {
  initialStep?: string;
  onStepChange?: (prevStep: string, nextStep: string) => void;
  onGoNext?: () => void;
  onGoBack?: () => void;
  middlewares?: Middleware[];
};

export const useMultiStep = (
  options: UseMultiStepOptions = {}
): MultiStepReturnType => {
  const [steps, setSteps] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(options?.initialStep || '');

  const currentStepIndex = steps.indexOf(currentStep);
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;
  const visitedSteps = steps.filter((_, i) => i <= currentStepIndex);
  const remainingSteps = steps.filter((step) => !visitedSteps.includes(step));
  const pastSteps = visitedSteps.filter((vs) => vs !== currentStep);
  const percentProgress = ((currentStepIndex + 1) / steps.length) * 100;

  const isRemainingStep = (step: string) => remainingSteps.includes(step);
  const isPastStep = (step: string) => pastSteps.includes(step);
  const isCurrentStep = (step: string) => step === currentStep;
  const isVisitedStep = (step: string) => visitedSteps.includes(step);

  const registerStep = (newStep: string) => {
    setSteps((prev) => Array.from(new Set([...prev, newStep])));
  };

  const unregisterStep = (stepToRemove: string) => {
    setSteps((prev) => {
      const oldSet = new Set([...prev]);
      oldSet.delete(stepToRemove);

      return Array.from(oldSet);
    });
  };

  const getNextStep = (step: string) => {
    const stepIndex = steps.indexOf(step);
    const nextStep = steps[stepIndex + 1];

    if (!nextStep) return undefined;
    return nextStep;
  };

  const getPrevStep = (step: string) => {
    const stepIndex = steps.indexOf(step);
    const prevStep = steps[stepIndex - 1];

    if (!prevStep) return undefined;
    return prevStep;
  };

  const goNext = () => {
    setCurrentStep((prev) => {
      const stepIndex = steps.indexOf(prev);
      const nextStep = steps[stepIndex + 1];

      if (!nextStep) return prev;
      if (options?.onStepChange) options.onStepChange(prev, nextStep);
      if (options?.onGoNext) options.onGoNext();
      return nextStep;
    });
  };

  const goBack = () => {
    setCurrentStep((prev) => {
      const stepIndex = steps.indexOf(prev);
      const prevStep = steps[stepIndex - 1];

      if (stepIndex === -1) return prev;
      if (options?.onStepChange) options.onStepChange(prev, prevStep);
      if (options?.onGoBack) options.onGoBack();
      return prevStep;
    });
  };

  const goToStep = (step: string) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if (steps.length > 0 && !currentStep) {
      setCurrentStep(steps[0]);
    }
  }, [steps, currentStep]);

  useEffect(() => {
    const onStepEnter = () => {
      if (!currentStep) return;

      const actions = {
        steps,
        visitedSteps,
        remainingSteps,
        currentStep,
        currentStepIndex,
        isFirstStep,
        isLastStep,
        percentProgress,
        isRemainingStep,
        isPastStep,
        isCurrentStep,
        isVisitedStep,
        unregisterStep,
        registerStep,
        getNextStep,
        getPrevStep,
        goNext,
        goBack,
        goToStep,
      };

      if (!!options.middlewares?.length) {
        options.middlewares.forEach((mid) => {
          if (typeof mid === 'function') {
            mid(actions);
            return;
          }
          if (mid?.steps?.includes(currentStep)) {
            mid.fn(actions);
            return;
          }
          if (mid?.skip?.includes(currentStep)) {
            return;
          }
          mid.fn(actions);
        });
      }
    };

    onStepEnter();
  }, [currentStep, options.middlewares]);

  return {
    steps,
    visitedSteps,
    remainingSteps,
    currentStep,
    currentStepIndex,
    isFirstStep,
    isLastStep,
    percentProgress,
    isRemainingStep,
    isPastStep,
    isCurrentStep,
    isVisitedStep,
    unregisterStep,
    registerStep,
    getNextStep,
    getPrevStep,
    goNext,
    goBack,
    goToStep,
  };
};

// ==========================================================================
// MultiStep Context

const MultiStepContext = createContext<MultiStepReturnType | undefined>(
  undefined
);

export const useMultiStepContext = () => {
  const ctx = useContext(MultiStepContext);
  if (!ctx) throw new Error('Missing MultiStep');
  return ctx;
};

const Root = (props: PropsWithChildren<MultiStepReturnType>) => (
  <MultiStepContext.Provider value={props}>
    {props.children}
  </MultiStepContext.Provider>
);

// ==========================================================================
// Step

const Step = ({ children, name }: PropsWithChildren<{ name: string }>) => {
  const ref = useRef(0);
  const { registerStep, isCurrentStep, unregisterStep } = useMultiStepContext();

  useEffect(() => {
    if (ref.current === 0) {
      registerStep(name);
    }
    ref.current += 1;

    return () => unregisterStep(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCurrentStep(name)) return <>{children}</>;
  return <></>;
};

export const MultiStep = {
  Root,
  Step,
};
