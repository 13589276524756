import {
  DisplayProps,
  FlexboxProps,
  SizingProps,
  SpacingProps,
  TypographyProps,
} from '@mui/system';

import Link from 'next/link';

import { ButtonSizes, Buttons, spacing, theme } from '@hl-portals/constants';

import { CSSVariants, isInternalLink } from '@hl-portals/helpers';

import { Box, BoxTypes } from '../Box';
import { useBrandTheme } from '../BrandTheme';
import Tooltip from '../Tooltip';
import { ButtonSpinner, ButtonVariant, ButtonWrapper } from './styles';

export type ButtonSize = CSSVariants<ButtonSizes>;

export type ButtonProps = {
  id?: string;
  children: React.ReactNode;
  color?: Buttons;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  href?: string;
  type?: 'submit' | 'button';
  target?: '_self' | '_blank';
  prefetch?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  as?: React.ElementType;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  tooltip?: string;
  tooltipProps?: BoxTypes;
} & Partial<SpacingProps> &
  Partial<SizingProps> &
  Partial<DisplayProps> &
  Partial<FlexboxProps> &
  Partial<TypographyProps>;

export const Button = (props: ButtonProps): React.ReactElement => {
  const {
    children,
    href,
    disabled,
    color = 'primary',
    variant = 'contained',
    target,
    type = 'button',
    prefetch,
    onClick,
    onMouseEnter,
    size = 'small',
    iconLeft,
    iconRight,
    isLoading,
    tooltip,
    tooltipProps = {},
  } = props;

  const { colors } = useBrandTheme();
  const brandColors = colors?.primary ? colors : null;

  if (typeof href !== 'undefined' && !disabled) {
    const buttonTarget = isInternalLink(href) ? '_self' : '_blank';
    const prefetchProp = prefetch === false ? { prefetch: false } : {};

    const LinkComponent = (
      <Link href={href} {...prefetchProp} passHref>
        <ButtonWrapper
          size={size}
          color={color}
          variant={variant}
          px={spacing.m}
          py={spacing.s}
          target={target || buttonTarget}
          brandColors={brandColors}
          //@ts-ignore
          {...(target === '_blank' ||
            (buttonTarget === '_blank' && {
              rel: 'noopener noreferrer',
            }))}
          as="a"
          disabled={disabled}
          {...props}
        >
          {iconLeft && <Box mr={spacing.s}>{iconLeft}</Box>}
          {children}
          {iconRight && <Box ml={spacing.s}>{iconRight}</Box>}
        </ButtonWrapper>
      </Link>
    );

    if (tooltip) {
      return (
        <Tooltip message={tooltip} wrapperProps={tooltipProps}>
          {LinkComponent}
        </Tooltip>
      );
    }

    return LinkComponent;
  }

  const ButtonComponent = (
    <ButtonWrapper
      {...props}
      onMouseEnter={onMouseEnter}
      onClick={!isLoading ? onClick : () => {}}
      size={size}
      color={color}
      variant={variant}
      brandColors={brandColors}
      disabled={disabled}
      type={type}
      isLoading={isLoading}
    >
      {isLoading && <ButtonSpinner color={theme.buttons[color].color} />}
      {iconLeft && <Box mr={spacing.s}>{iconLeft}</Box>}
      {children}
      {iconRight && <Box ml={spacing.s}>{iconRight}</Box>}
    </ButtonWrapper>
  );

  if (tooltip) {
    return (
      <Tooltip message={tooltip} wrapperProps={tooltipProps}>
        {ButtonComponent}
      </Tooltip>
    );
  }

  return ButtonComponent;
};
