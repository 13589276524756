import { PropsWithChildren, createContext, useContext } from 'react';

export type BrandTheme = {
  colors?: {
    primary?: string;
    secondary?: string;
    tertiary?: string;
  };
  logoUrl?: string;
};

const BrandThemeContext = createContext<BrandTheme | undefined>(undefined);

export type BrandThemeProviderProps = {
  theme: BrandTheme;
};

export const BrandThemeProvider = ({
  children,
  theme,
}: PropsWithChildren<BrandThemeProviderProps>) => {
  return (
    <BrandThemeContext.Provider value={theme}>
      {children}
    </BrandThemeContext.Provider>
  );
};

export const useBrandTheme = () => {
  const ctx = useContext(BrandThemeContext);
  if (!ctx) {
    console.warn('Missing BrandThemeProvider');
    return { colors: {} };
  }
  return ctx;
};
