import { useEffect } from 'react';

import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { utils as authUtils } from '@hl-portals/libs/auth';

import { config } from '@hl-portals/helpers';

export const useAuthBoundary = async () => {
  const { data: session } = useSession();
  const router = useRouter();

  useEffect(() => {
    const isDevEquityApp =
      ['development', 'test'].includes(config.environmentName) &&
      router.query.equityApp === 'true';

    const skipAuth =
      isDevEquityApp ||
      (config.equityAppEnabled &&
        config.publicHosts &&
        config.publicHosts.includes(window.location.host));

    if (
      typeof session !== 'undefined' &&
      !session?.user &&
      !authUtils.isPublicOrTokenizedPage(router?.pathname) &&
      router?.pathname !== '/logout' &&
      !skipAuth
    ) {
      signIn('homelight', {
        callbackUrl: authUtils.getSigninCallbackUrl(window?.location),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);
};
