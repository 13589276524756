/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useImperativeHandle, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatCurrency } from '@hl-portals/helpers';

import { BoxTypes } from '../../Box';
import { Input, InputProps } from '../../Input';
import InputGroup from '../../InputGroup';
import { ParagraphProps } from '../../Typography';
import { InputError } from '../components/input-error';

type Props = {
  name: string;
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  optional?: boolean;
  tooltip?: string;
  testId?: string;
  showSymbol?: boolean;
  isFocused?: boolean;
  note?: string;
  containerProps?: BoxTypes;
} & InputProps;

export const CurrencyInput = ({
  name,
  label,
  labelProps,
  optional,
  tooltip,
  onChange,
  showSymbol = true,
  testId = 'input-currency',
  isFocused,
  note,
  containerProps,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { formState, register, setValue, clearErrors, watch } =
    useFormContext();

  const { ref, ...rest } = register(name, {
    onChange: (e) => {
      clearErrors(name);
      e.target.value = formatCurrency({
        value: e.target.value,
        raw: true,
      });
      onChange && onChange(e);
    },
  });

  const error = formState.errors[name];
  const value = watch(name);

  useImperativeHandle(ref, () => inputRef.current);

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  return (
    <InputGroup
      label={label}
      note={note}
      labelProps={labelProps}
      {...(optional && { note: 'Optional ' })}
      tooltip={tooltip}
      flexDirection="column"
      noteProps={{ fontStyle: 'normal' }}
      width="100%"
      {...containerProps}
    >
      <Input
        variant={showSymbol ? 'currency' : 'normal'}
        maxLength={12}
        type="tel"
        ref={inputRef}
        {...(value && { onClear: () => setValue(name, '') })}
        {...rest}
        isError={!!formState.errors[name]}
        data-test={testId}
        {...props}
      />
      {!!error && <InputError error={error} />}
    </InputGroup>
  );
};
