import { useState } from "react";

import { copyToClipboard } from "@hl-portals/helpers";

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  function onCopyToClipboard(value: string, toastText?: string) {
    copyToClipboard(value, toastText);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return { copyToClipboard: onCopyToClipboard, copied };
}
