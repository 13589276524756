import { theme } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';

export type ProgressBarProps = {
  progress: string | number;
  color?: string;
} & BoxTypes;

export const ProgressBar = ({
  progress,
  color,
  ...props
}: ProgressBarProps): React.ReactElement => {
  return (
    <Box height="8px" width="100%" borderRadius="8px" {...props}>
      <Box
        borderRadius="8px"
        width={`${progress}%`}
        transition="width .2s ease-out"
        bgcolor={color || theme.colors.electricBlue}
      />
    </Box>
  );
};
