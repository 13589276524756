import { PropsWithChildren } from 'react';

import { Colors, colors } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';
import { Icon, IconTypeProp } from '../Icon';
import { Paragraph } from '../Typography';

type Variant = 'info' | 'warning' | 'danger';
type VariantsObj = Record<
  Variant,
  Record<'background' | 'color', Colors | string>
>;

type AlertProps = {
  variant: 'info' | 'warning' | 'danger';
  icon?: IconTypeProp;
} & BoxTypes;

const Alert = ({
  children,
  variant,
  icon = 'info',
  ...props
}: PropsWithChildren<AlertProps>) => {
  const variants: VariantsObj = {
    info: {
      background: colors['blue100'],
      color: 'blue700',
    },
    warning: {
      background: colors['warningLight'],
      color: 'warningDark',
    },
    danger: {
      background: colors['red200'],
      color: 'red700',
    },
  };

  const v = variants[variant];

  return (
    <Box
      p="20px"
      borderRadius="4px"
      bgcolor={v.background}
      gap="6px"
      alignItems="flex-start"
      {...props}
    >
      <Icon
        type={icon}
        size={20}
        fill={v.color as Colors}
        color={v.color as Colors}
      />
      <Paragraph variant="text-small" color={v.color as Colors}>
        {children}
      </Paragraph>
    </Box>
  );
};

export default Alert;
