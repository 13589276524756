import {
  BordersProps,
  DisplayProps,
  FlexboxProps,
  GridProps,
  PaletteProps,
  PositionsProps,
  ShadowsProps,
  SizingProps,
  SpacingProps,
  TypographyProps,
  borders,
  display,
  flexbox,
  grid,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from '@mui/system';
import styled, { SimpleInterpolation, css } from 'styled-components';

import {
  CSSVariants,
  addMediaQueries,
  buildPropertyRules,
} from '@hl-portals/helpers';

type OverflowType = 'auto' | 'hidden' | 'visible' | 'scroll' | 'clip';

interface Props {
  as?: React.ElementType;
  overflowY?: CSSVariants<OverflowType>;
  overflowX?: CSSVariants<OverflowType>;
  animation?: string | SimpleInterpolation;
  transition?: string;
}

const overflowValues = ['auto', 'hidden', 'visible', 'scroll', 'clip'];

export type BoxTypes = Props &
  DisplayProps &
  SpacingProps &
  FlexboxProps &
  GridProps &
  PositionsProps &
  PaletteProps &
  BordersProps &
  ShadowsProps &
  SizingProps &
  TypographyProps & {
    cursor?: 'pointer' | 'auto' | 'default' | 'no-drop' | 'not-allowed';
    pointerEvents?: 'auto' | 'none';
    opacity?: string;
    background?: string;
    children?: React.ReactNode;
  };

export const Box = styled.div<BoxTypes>`
  display: flex;
  box-sizing: border-box;
  ${({ as }) => as === 'a' && `cursor: pointer;`}
  ${display}
  ${flexbox}
  ${grid}
  ${sizing}
  ${spacing}
  ${positions}
  ${typography}
  ${palette}
  ${borders}
  ${shadows}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ background }) => background && `background: ${background};`}
  ${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ overflowY, theme }) =>
    addMediaQueries({
      theme,
      prop: overflowY || 'visible',
      styles: buildPropertyRules('overflow-y', overflowValues),
    })}
  ${({ overflowX, theme }) =>
    addMediaQueries({
      theme,
      prop: overflowX || 'visible',
      styles: buildPropertyRules('overflow-x', overflowValues),
    })}
  ${({ transition }) => transition && `transition: ${transition};`}
  ${({ animation }) =>
    animation &&
    css`
      animation: ${animation};
    `}
`;

export const TransformedBox = styled(Box)<{ transform: string }>`
  ${({ transform }) => `
    transform: ${transform};
  `}
`;
