import { createGlobalStyle } from 'styled-components';

import { paragraphHelper } from '@hl-portals/helpers';

const ToastifyStyle = createGlobalStyle`
.Toastify {
  &__close-button {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background: rgba(255, 255, 255, 0);
    border-radius: 50px;
    opacity: .8;

    > svg {
      fill: #8E929C;
    }
  }

  &__toast {
    min-height: 52px;
    border-radius: 8px;
    padding: 20px;

    @media(max-width: 480px) {
      width: 80vw;
      bottom: 20px;
      left: 10vw;
    }

    &-container {
      width: auto;
      max-width: 500px;
    }

    &-body {
      margin: 0;
      margin-right: 20px;
      padding-left: 40px;
      align-items: center;
      line-height: 14px;
      color: ${({ theme }) => theme.colors.white};
      ${({ theme }) => paragraphHelper({ type: 'text-small', theme })}
    }

    &--default, &--info, &--success, &--warning, &--error, &--mail {
      background: ${({ theme }) => theme.colors.darkGray};
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top:50%;
        transform: translateY(-50%);
      }
    }

    &--error {
      &::before {
        background-image: url("/icons/times-circle.svg");
        width: 20px; 
        height: 20px;
      }
    }

    &--success {
      &::before {
        background: url("/icons/check-circle-green.svg");
        background-repeat: no-repeat;
        background-position: center center;
        width: 32px; 
        height: 32px;
      }
    }

    &--warning {
      &::before {
        background-image: url("/icons/exclamation-circle.svg");
        width: 24px; 
        height: 24px;
      }
    }

    &--mail {
      &::before {
        background-image: url("/icons/letter-solid.svg");
        width: 24px; 
        height: 18px;
      }
    }

  }

  &__progress-bar {
    background: ${({ theme }) => theme.colors.electricBlue};
  }
}
`;

export default ToastifyStyle;
