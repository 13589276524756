import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { STATES } from '@hl-portals/constants';

import InputGroup from '../../InputGroup';
import { Select } from '../../Select';

export type StateSelectProps = {
  name: string;
  label: string;
  testId?: string;
};

export const StateSelect = ({ name, label, testId }: StateSelectProps) => {
  const { formState, setValue, watch } = useFormContext();
  const error = formState.errors[name];
  const value = watch(name);

  const STATE_OPTIONS = useMemo(
    () =>
      Object.entries(STATES).map((state) => {
        const [stateCode, stateName] = state;
        return { stateCode, stateName };
      }),
    []
  );

  return (
    <InputGroup label={label} error={error?.message?.toString()}>
      <Select
        options={STATE_OPTIONS.map(({ stateName, stateCode }) => ({
          text: stateName,
          value: stateCode,
        }))}
        placeholder="Select state"
        onChange={(value) => setValue(name, value)}
        selectedValue={value}
        isError={!!error}
        height="45px"
        dataTest={testId}
      />
    </InputGroup>
  );
};
