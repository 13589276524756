type Args = {
  propertyAddress: string;
  propertyUnit?: string;
  propertyCity: string;
  propertyState: string;
  propertyPostalCode: string;
};

export function getFullAddress({
  propertyAddress,
  propertyUnit = '',
  propertyCity,
  propertyState,
  propertyPostalCode,
}: Args) {
  return [
    `${propertyAddress}`,
    propertyUnit ? `#${propertyUnit}` : null,
    `${propertyCity}`,
    `${propertyState} ${propertyPostalCode}`,
  ]
    .filter(Boolean)
    .join(', ');
}
