import { DisplayProps, SpacingProps } from '@mui/system';
import { ThemeContext } from 'styled-components';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { AvatarImg, AvatarWrapper } from './styles';

export interface Props {
  type: 'normal' | 'alert' | 'image';
  alt?: string;
  src?: string;
  initials?: string;
  size?: 'small' | 'medium' | 'big' | 'extraBig' | 'xxl' | 'xxxl' | number;
  surface?: 'dark' | 'light' | 'blue' | 'none' | 'alert';
  squared?: boolean;
  reverse?: boolean;
  fontSize?: number;
  imageStyles?: {};
  'data-test'?: string;
}

type AvatarProps = Props & SpacingProps & DisplayProps;

export const Avatar = (props: AvatarProps): React.ReactElement => {
  const theme = useContext(ThemeContext);
  const {
    initials,
    alt = '',
    src,
    type,
    size = 'small',
    surface = 'dark',
    squared,
    fontSize,
    imageStyles = {},
    'data-test': dataTest,
  } = props;

  const PLACEHOLDER = 'https://via.placeholder.com/100';
  const retries = useRef(1);
  const [imgSrc, setImgSrc] = useState(PLACEHOLDER);

  const retry = useCallback(() => {
    if (retries.current <= 3) {
      retries.current += 1;

      setTimeout(() => {
        setImgSrc('');
        setImgSrc(src || '');
      }, 1000);
      return;
    }
    console.error('Unable to load the image');
  }, [src]);

  useEffect(() => {
    setImgSrc(src ?? PLACEHOLDER);
  }, [src]);

  if (type === 'image') {
    return (
      <AvatarWrapper
        size={size}
        surface={surface}
        squared={squared}
        data-test={`card-avatar-${type}`}
        {...props}
      >
        <AvatarImg
          src={imgSrc}
          alt={alt}
          width={theme.avatar.size[size]?.size ?? size}
          height={theme.avatar.size[size]?.size ?? size}
          data-test={dataTest}
          onError={() => retry()}
          style={imageStyles}
        />
      </AvatarWrapper>
    );
  }

  const isAlert = type === 'alert';
  const content = isAlert ? '!' : initials;
  return (
    <AvatarWrapper
      size={size}
      surface={surface}
      squared={squared}
      data-test={`card-avatar-${type}`}
      fontSize={fontSize}
      {...props}
      display="flex"
    >
      {content}
    </AvatarWrapper>
  );
};
