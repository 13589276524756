import {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Box, Input, InputProps } from '@hl-portals/ui';

import { useHandleClickOutside } from '@hl-portals/hooks';

import { ResultItem, ResultWrapper } from './styles';

export type AutocompleteProps<T> = {
  onChange: (params: {
    term: string;
    selectedIndex: number;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onEnter: (params: {
    option: T;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  options: T[] & { disabled?: boolean };
  autofocus?: boolean;
  placeholder?: string;
  separator?: () => React.ReactElement;
  children?: (params: { option: T; index: number }) => React.ReactElement;
  value: string;
  isError?: boolean;
  name?: string;
  fallback?: (params: {
    term: string;
    setTerm: Dispatch<SetStateAction<string>>;
    setIsResultOpen: Dispatch<SetStateAction<boolean>>;
  }) => React.ReactElement;
  onFallback?: (params: {
    term: string;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  tabProtection?: boolean;
  variant?: 'top' | 'bottom';
  disabled?: boolean;
  optionKey?: string;
};

type InputPropExtras<T> = Omit<InputProps, keyof AutocompleteProps<T>>;

const Autocomplete = <T,>({
  autofocus,
  placeholder,
  onChange: onChangeProp,
  onEnter,
  children,
  options,
  separator: CustomDivider,
  value = '',
  name,
  isError,
  onBlur,
  fallback,
  onFallback,
  tabProtection = false,
  variant = 'bottom',
  optionKey,
  ...extraProps
}: AutocompleteProps<T> & InputPropExtras<T>): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>();
  const [term, setTerm] = useState(value);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  useEffect(() => {
    if (inputRef.current && autofocus) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  useEffect(() => {
    setTerm(value);
  }, [value]);

  const ref = useHandleClickOutside<HTMLInputElement>({
    onClickOutside: () => {
      if (isResultOpen && tabProtection) setTerm('');
      setIsResultOpen(false);
    },
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsResultOpen(true);
    setSelectedIndex(-1);
    setSelectedOption(false);
    onChangeProp({ term: event.target.value, selectedIndex, setTerm });
  };

  // @ts-ignore
  const onClick = ({ option }) => {
    if (option.disabled || option.loading) return;

    onEnter({ option, setTerm });
    setIsResultOpen(false);
  };

  // @ts-ignore
  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Tab':
        if (!selectedOption && tabProtection) setTerm('');

        setIsResultOpen(false);
        break;
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex(
          selectedIndex === options.length - 1
            ? options.length - 1
            : selectedIndex + 1
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex(selectedIndex === 0 ? 0 : selectedIndex - 1);
        break;
      case 'Enter':
        event.preventDefault();
        if (options.length === 1) {
          onEnter({ option: options[0], setTerm });
          setIsResultOpen(false);
        } else if (selectedIndex > -1) {
          onEnter({ option: options[selectedIndex], setTerm });
        } else if (fallback && onFallback && options.length === 0) {
          onFallback({ term, setTerm });
        }
        setSelectedOption(true);
        setIsResultOpen(false);
        break;
      case 'Escape':
        if (!isResultOpen) break;
        event.preventDefault();
        setTerm('');
        setIsResultOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <Box position="relative" ref={ref}>
      <Box position="relative" zIndex={isResultOpen && 100} width={1}>
        <Input
          autoComplete="off"
          placeholder={placeholder}
          value={term}
          onChange={onChange}
          onKeyDown={onKeyDown}
          isError={isError}
          onBlur={onBlur}
          name={name}
          // @ts-ignore
          ref={inputRef}
          {...extraProps}
        />
      </Box>

      <ResultWrapper
        isOpen={term?.length > 0 && isResultOpen}
        variant={variant}
      >
        <div>
          {options.map((option, index) => {
            const key = `autocomplete-result-${index}`;

            if (!children) {
              return (
                <ResultItem
                  key={key}
                  selected={selectedIndex === index}
                  p="12px"
                  onMouseOver={() => setSelectedIndex(index)}
                  onClick={() => onClick({ option })}
                  variant={variant}
                >
                  {term}
                </ResultItem>
              );
            }

            return (
              <Fragment key={key}>
                <ResultItem
                  selected={selectedIndex === index}
                  onMouseOver={() => setSelectedIndex(index)}
                  onClick={() => onClick({ option })}
                  variant={variant}
                >
                  {children({ option, index })}
                </ResultItem>
                {/* @ts-ignore */}
                {index !== options.length - 1 && <CustomDivider />}
              </Fragment>
            );
          })}
          {options.length === 0 && fallback && (
            <ResultItem variant={variant} selected p="12px">
              {fallback({ term, setTerm, setIsResultOpen })}
            </ResultItem>
          )}
        </div>
      </ResultWrapper>
    </Box>
  );
};

export default Autocomplete;
