import { FocusEvent, KeyboardEventHandler, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { getFullAddress } from '@hl-portals/helpers';

import { usePropertyAutocomplete } from '@hl-portals/hooks';

import { SlideDown } from '../../Animations';
import Autocomplete, { AutocompleteProps } from '../../Autocomplete';
import { Box, BoxTypes } from '../../Box';
import Divider from '../../Divider';
import { InputProps } from '../../Input';
import InputGroup from '../../InputGroup';
import Spinner from '../../Spinner';
import { Paragraph, ParagraphProps } from '../../Typography';
import { InputError } from '../components/input-error';

type PropertySuggestionOption =
  | PropertySuggestion
  | { loading: boolean; attributes?: any };

type PropertySuggestion = {
  attributes: {
    city: string;
    display_address: string;
    postal_code: string;
    state_or_province: string;
    hl_full_address: string;
    street_number: string;
    street_name: string;
  };
  id: string;
  type: 'property_suggestion';
  loading?: boolean;
};

type OnEnterArgs = {
  hl_full_address: string;
  postal_code: string;
  city: string;
  state_or_province: string;
  full_address: string;
};

type Props = {
  name: string;
  label?: string;
  labelProps?: ParagraphProps & BoxTypes;
  testId?: string;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onEnter?: ({
    hl_full_address,
    postal_code,
    city,
    state_or_province,
  }: OnEnterArgs) => void;
} & Omit<
  AutocompleteProps<PropertySuggestion>,
  'onChange' | 'value' | 'onEnter' | 'options'
> &
  InputProps;

export const AddressInput = ({
  name,
  label = 'Property address',
  labelProps,
  placeholder = 'Enter property address',
  testId = 'input-address',
  onEnter,
  onKeyUp,
  hint,
  ...props
}: Props) => {
  const { formState, watch, setValue, clearErrors } = useFormContext();
  const [focused, setFocused] = useState(false);
  const error = formState.errors[name];
  const value = watch(name);

  const onInputFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
    if (typeof props.onFocus === 'function') props.onFocus(e);

    setFocused(true);
  };

  const onInputBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    if (typeof props.onBlur === 'function') props.onBlur(e);

    setFocused(false);
  };

  const overrideProps = {
    onFocus: onInputFocus,
    onBlur: onInputBlur,
  };

  const forwardedProps = {
    ...props,
    ...overrideProps,
  };

  const { data, isLoading } = usePropertyAutocomplete(value);

  return (
    <InputGroup label={label} labelProps={labelProps} width="100%">
      <Autocomplete<PropertySuggestionOption>
        {...forwardedProps}
        hint={isLoading || (focused && data?.length) ? undefined : hint}
        separator={() => <Divider ml={'20px'} maxWidth={467} />}
        tabProtection={false}
        isError={!!error}
        value={value ?? ''}
        onKeyUp={onKeyUp}
        onChange={({ term, setTerm }) => {
          setTerm(term);
          setValue(name, term);
          clearErrors(name);
        }}
        onEnter={({ option, setTerm }) => {
          const result = (option as PropertySuggestionOption).attributes;
          if (!result) return;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { hl_full_address, city, state_or_province, postal_code } =
            result;

          const fullAddress = getFullAddress({
            propertyAddress: hl_full_address,
            propertyCity: city,
            propertyState: state_or_province,
            propertyPostalCode: postal_code,
          });

          setTerm(hl_full_address);
          setValue(name, hl_full_address);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onEnter && onEnter({ ...result, full_address: fullAddress });
        }}
        options={isLoading ? [{ loading: true }] : data}
        placeholder={placeholder}
        data-test={testId}
      >
        {({ option, index }) =>
          option.loading ? (
            <Box flexDirection="row" width={1} py="12px" px={'20px'}>
              <Box mr="12px" mt="4px">
                <Spinner />
              </Box>
              <Paragraph
                variant="text-small"
                color="black"
                mr="12px"
                data-test={`address-input-result-loading`}
              >
                Finding address... Please wait.
              </Paragraph>
            </Box>
          ) : (
            <Box flexDirection="column" width={1} py="12px" px={'20px'}>
              <Paragraph
                variant="text-small"
                color="black"
                mr="12px"
                data-test={`address-input-result-${index}`}
              >
                {option.attributes.display_address}
              </Paragraph>
            </Box>
          )
        }
      </Autocomplete>
      {!!error && (
        <SlideDown>
          <InputError error={error} />
        </SlideDown>
      )}
    </InputGroup>
  );
};
