import { useEffect, useMemo, useRef } from 'react';

import { useRouter } from 'next/router';

import { useBrowserEvent, useScreenSize } from '@hl-portals/hooks';

import { FadeIn } from '../Animations';
import { Box } from '../Box';
import { Button } from '../Button';
import Checkbox from '../Checkbox';
import { FileUploader, useFileUpload } from '../FileUploader';
import { Icon } from '../Icon';
import { IndentedText, Paragraph } from '../Typography';

type ItemID = 'living_room' | 'bedroom' | 'bathroom' | 'exterior' | 'kitchen';

type ItemType = {
  id: ItemID;
  label: string;
  status?: 'pending' | 'completed';
  icon: 'bedroom' | 'bathroom' | 'kitchen' | 'livingRoom' | 'propertyExterior';
};

type ItemProps = {
  item: ItemType;
  border?: boolean;
};

const Item = ({ item, border }: ItemProps) => (
  <Box
    justifyContent="space-between"
    alignItems="center"
    p="20px 16px"
    pl={border ? '16px' : '0px'}
    border={border ? '1px solid #EEF0F6' : 'none'}
    borderRadius="8px"
  >
    <Box alignItems="center" gap="8px">
      <Icon type={item.icon} />
      <Paragraph>{item.label}</Paragraph>
    </Box>
    {item.status && (
      <Icon
        type={
          item.status === 'pending'
            ? 'warningCircleFilled'
            : 'successCircleFilled'
        }
      />
    )}
  </Box>
);

type PropertyInfoSectionProps = {
  address: string;
  items: ItemType[];
};

const PhotosInfoSection = ({ address, items }: PropertyInfoSectionProps) => {
  const requirements = [
    'Please prepare a minimum of 10-12 photos that cover the mentioned areas of the home.',
    "Add recent photos depicting the home's current condition.",
    'Make sure the pictures are high-quality.',
    'Have good lighting and declutter the space. ',
  ];

  return (
    <Box flexDirection="column">
      <Box flexDirection="column" mb="32px">
        <Paragraph variant="heading-2" mb="16px">
          Upload Property Photos
        </Paragraph>
        <Box>
          <Icon type="mapMarker" />
          <Paragraph>{address}</Paragraph>
        </Box>
      </Box>

      <Box flexDirection="column" mb="24px">
        <Paragraph mb="12px">
          To evaluate the property's condition, we need updated photos of the
          property.
        </Paragraph>
        <Paragraph>Include photos of the following property areas:</Paragraph>
      </Box>

      <Box flexDirection="column" mb="40px">
        {items.map((item) => (
          <Item key={item.label} item={item} />
        ))}
      </Box>

      <Box
        p="24px 16px"
        flexDirection="column"
        border="1px solid #EEF0F6"
        borderRadius="8px"
        bgcolor="#F8F8FB"
      >
        <Paragraph variant="text-xsmall-semi-bold" mb="16px">
          Photo Requirements
        </Paragraph>
        <Box flexDirection="column" gap="8px">
          {requirements.map((r) => (
            <IndentedText key={r} variant="text-xsmall" indentation="8px">
              {r}
            </IndentedText>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

type AditionalPhotosInfosSectionProps = {
  address: string;
  items: ItemType[];
};

const AditionalPhotosInfosSection = ({
  address,
  items,
}: AditionalPhotosInfosSectionProps) => {
  const pendingPhotos = useMemo(
    () => items.filter(({ status }) => status === 'pending'),
    [items]
  );

  const completedPhotos = useMemo(
    () => items.filter(({ status }) => status === 'completed'),
    [items]
  );

  return (
    <Box flexDirection="column">
      <Box flexDirection="column" mb="32px">
        <Paragraph variant="heading-2" mb="16px">
          Upload Additional Photos
        </Paragraph>
        <Box>
          <Icon type="mapMarker" />
          <Paragraph>{address}</Paragraph>
        </Box>
      </Box>

      <Box
        flexDirection="column"
        mb="32px"
        p="24px"
        border="1px solid #DBDFE6"
        borderLeft="8px solid #FF9000"
        borderRadius="8px"
      >
        <Paragraph fontWeight="bold" mb="4px">
          Missing property photos
        </Paragraph>
        <Paragraph lineHeight="180%" mb="12px">
          We've identified missing photos for the following areas. Please
          provide them to proceed with the property evaluation.
        </Paragraph>

        <Box flexDirection="column" gap="8px">
          {pendingPhotos.map((item) => (
            <Item key={item.label} item={item} border />
          ))}
        </Box>
      </Box>

      <Box flexDirection="column">
        <Paragraph color="gray500" mb="8px">
          Completed
        </Paragraph>
        <Box flexDirection="column" gap="4px">
          {completedPhotos.map((item) => (
            <Item key={item.label} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

type AuthCheckboxProps = {
  isAuthorized: boolean;
  onAuthorize: () => void;
};

const AuthCheckbox = ({ isAuthorized, onAuthorize }: AuthCheckboxProps) => (
  <Box alignItems="center" gap="10px" mt="32px">
    <Checkbox checked={isAuthorized} onChange={() => onAuthorize()} />
    <Paragraph variant="text-xsmall">
      I authorize HomeLight to internally utilize the photos I upload solely for
      evaluating my client's eligibility for HomeLight products and services of
      their interest.
    </Paragraph>
  </Box>
);

type PropertyPhotoUploadProps = {
  address?: string;
  onSubmit: any;
  leadId: string;
  disabled?: boolean;
  isAuthorized: boolean;
  onAuthorize: () => void;
  missingPhotos: ItemID[];
  propertyType?: string
};

export const Upload = ({
  address = '',
  onSubmit,
  leadId,
  disabled,
  isAuthorized,
  onAuthorize,
  missingPhotos = [],
  propertyType
}: PropertyPhotoUploadProps) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const { isDesktop } = useScreenSize();
  const { selectedFiles } = useFileUpload();
  const { publish } = useBrowserEvent('OPEN_FILE_PICKER');

  const hasSelectedPhotos = selectedFiles.length > 0;
  const hasMissingPhotos = missingPhotos.length > 0;

  const itemsWithStatus: ItemType[] = useMemo(() => {
    const items = [
      { id: 'bedroom', icon: 'bedroom', label: 'Bedrooms' },
      { id: 'bathroom', icon: 'bathroom', label: 'Bathrooms' },
      { id: 'kitchen', icon: 'kitchen', label: 'Kitchen' },
      { id: 'living_room', icon: 'livingRoom', label: 'Living room' },
    ] as ItemType[];

    if (propertyType?.toLowerCase() !== "condominium") {
      items.push({ id: 'exterior', icon: 'propertyExterior', label: 'Property Exterior' })
    }
    if (hasMissingPhotos) {
      return items.map((item) => {
        if (missingPhotos.includes(item.id)) {
          return { ...item, status: 'pending' };
        }
        return { ...item, status: 'completed' };
      });
    }
    return items;
  }, [missingPhotos, hasMissingPhotos]);

  useEffect(() => {
    if (hasSelectedPhotos) {
      bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hasSelectedPhotos, bottomRef]);

  const goBack = () => {
    router.back();

    // fallback if this page has no history
    router.push('/dashboard');
  };

  return (
    <FadeIn width="100%" flexDirection="column" bgcolor="white">
      <Box p="12px" justifyContent="flex-end" borderBottom="1px solid #DBDFE6">
        <Icon type="times" onClick={goBack} />
      </Box>

      <Box
        maxWidth="1200px"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="stretch"
        m="0 auto"
        p={{ xs: '16px', sm: '40px 40px 80px 40px' }}
        gap={{ xs: '8px', sm: '80px' }}
      >
        <Box width="100%">
          {hasMissingPhotos ? (
            <AditionalPhotosInfosSection
              items={itemsWithStatus}
              address={address}
            />
          ) : (
            <PhotosInfoSection items={itemsWithStatus} address={address} />
          )}
        </Box>

        <Box flexDirection="column">
          <FileUploader
            fileType="image"
            accept={['png', 'jpg', 'jpeg', 'heic']}
            multipleUpload
            loadingText="Uploading Information..."
            maxFileSize={20}
            formatSizeDescription={
              'Upload, PNG, JPG, JPEG or HEIC files, max file size of 20 MB each.'
            }
            label="Drop your Property Photos here or"
            leadId={leadId}
            fileListPosition={'bottom'}
            hideOnMobile
            uniqueOnly
          />
          {isDesktop && (
            <AuthCheckbox
              isAuthorized={isAuthorized}
              onAuthorize={onAuthorize}
            />
          )}
        </Box>
      </Box>

      {isDesktop ? (
        <Box
          p="16px 24px"
          justifyContent="flex-end"
          borderTop="1px solid #DBDFE6"
          bgcolor="white"
          position="sticky"
          bottom="0"
          left="0"
          right="0"
        >
          <Button size="large" disabled={disabled} onClick={onSubmit}>
            Upload Photos
          </Button>
        </Box>
      ) : (
        <Box
          flexDirection="column"
          position="sticky"
          bottom="0"
          left="0"
          right="0"
        >
          {hasSelectedPhotos && (
            <Box bgcolor="white" p="16px">
              <AuthCheckbox
                isAuthorized={isAuthorized}
                onAuthorize={onAuthorize}
              />
            </Box>
          )}
          <Box
            p={{ xs: '16px 16px 28px 16px', sm: '16px 24px' }}
            borderTop="1px solid #DBDFE6"
            bgcolor="white"
          >
            {hasSelectedPhotos ? (
              <Button
                width="100%"
                size="large"
                disabled={disabled}
                onClick={onSubmit}
              >
                Upload Photos
              </Button>
            ) : (
              <Button width="100%" size="large" onClick={() => publish()}>
                Select Photos
              </Button>
            )}
          </Box>
        </Box>
      )}

      <div ref={bottomRef} />
    </FadeIn>
  );
};
