import { z } from 'zod';

import { isFullName, isValidEmail, isValidPhone } from '../string';

const ERRORS = {
  required: (label: string) => `Please enter a ${label}`,
  valid: (label: string) => `Please enter a valid ${label}`,
};

type TypeOptions = {
  optional?: boolean;
  required_error?: string;
  message?: string;
};

// ===============================================================
// Phone

const defaultPhoneOptions = {
  optional: false,
  required_error: ERRORS.required('phone number'),
  message: ERRORS.valid('phone number'),
};

const phone = (options: TypeOptions = defaultPhoneOptions) => {
  if (options.optional) {
    return z
      .string()
      .optional()
      .refine(
        (value) => {
          if (!value || value === '(___) ___-____') return true;
          return isValidPhone(value);
        },
        {
          message: options.message,
        }
      );
  }
  return z
    .string({
      required_error: options.required_error,
    })
    .refine(isValidPhone, {
      message: options.message,
    });
};

// ===============================================================
// Full Name

const defaultFullNameOptions = {
  optional: false,
  required_error: ERRORS.required('name'),
  message: ERRORS.valid('full name'),
};

const fullName = (options: TypeOptions = defaultFullNameOptions) => {
  if (options.optional) {
    return z
      .string()
      .optional()
      .refine((value) => (!!value ? isFullName(value) : true), {
        message: options.message,
      });
  }
  return z
    .string({
      required_error: options.required_error,
    })
    .refine(isFullName, {
      message: options.message,
    });
};

// ===============================================================
// Email

const defaultEmailOptions = {
  optional: false,
  required_error: ERRORS.required('email'),
  message: ERRORS.valid('email'),
};

const email = (options: TypeOptions = defaultEmailOptions) => {
  if (options.optional) {
    return z
      .string()
      .optional()
      .refine((value) => (!!value ? isValidEmail(value) : true), {
        message: options.message,
      });
  }
  return z
    .string({
      required_error: options.required_error,
    })
    .refine(isValidEmail, { message: options.message });
};

export const customZodTypes = { phone, fullName, email };
