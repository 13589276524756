import styled from 'styled-components';

import React, {
  ComponentPropsWithRef,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useId,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { useScrollIntoView } from '@hl-portals/hooks';

import { SlideDown } from '../../Animations';
import { Box, BoxTypes } from '../../Box';
import { Paragraph } from '../../Typography';
import { InputError } from '../components/input-error';

type RadioGroupProps = {
  name: string;
  label: React.ReactNode;
  direction?: 'row' | 'column';
} & BoxTypes;

const RadioContext = createContext<{ name: string }>({ name: '' });

export const RadioGroup = ({
  name,
  label,
  direction = 'column',
  children,
  ...props
}: PropsWithChildren<RadioGroupProps>) => {
  const { targetRef, scrollIntoView } = useScrollIntoView();
  const { formState, setFocus } = useFormContext();

  const error = formState.errors[name];

  useEffect(() => {
    const isFirstError = Object.keys(formState.errors)[0] === name;

    if (error && isFirstError) {
      setFocus(name);
      scrollIntoView();
    }
  }, [name, error, formState.errors, setFocus, scrollIntoView]);

  return (
    <Box flexDirection="column" gap="16px" ref={targetRef} {...props}>
      {typeof label === 'string' ? (
        <Paragraph variant="text-small">{label}</Paragraph>
      ) : (
        label
      )}
      <Box flexDirection={direction} gap="24px">
        <RadioContext.Provider value={{ name }}>
          {children}
        </RadioContext.Provider>
      </Box>

      {!!error && (
        <SlideDown>
          <InputError error={error} />
        </SlideDown>
      )}
    </Box>
  );
};

const StyledRadioInput = styled.input`
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  appearance: none;
  position: relative;
  border: 1px solid #dbdfe6;
  border-radius: 50%;
  background-color: transparent;
  transform: translateY(-0.075em);

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #46b6ff;
  }

  &:checked {
    border: 2px solid #46b6ff;
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export type RadioItemProps = {
  value: string;
  inputProps?: ComponentPropsWithRef<'input'>;
} & BoxTypes;

export const RadioItem = ({
  value,
  children,
  inputProps,
  ...props
}: PropsWithChildren<RadioItemProps>) => {
  const { name } = useContext(RadioContext);
  const id = useId();
  const { watch, setValue, clearErrors } = useFormContext();
  const formValue = watch(name);

  return (
    <Box as="label" htmlFor={id} gap="8px" cursor="pointer" {...props}>
      <StyledRadioInput
        {...inputProps}
        type="radio"
        id={id}
        name={name}
        checked={formValue === value}
        onChange={() => {
          clearErrors(name);
          setValue(name, value);
        }}
        data-test={`${name}-${value}`}
      />
      {typeof children === 'string' ? (
        <Paragraph>{children}</Paragraph>
      ) : (
        children
      )}
    </Box>
  );
};
