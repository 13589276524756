import styled, { css } from 'styled-components';

import { ChangeEvent, InputHTMLAttributes } from 'react';

import { Paragraph } from '../Typography';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  'data-test'?: string;
  label?: string;
  fullWidth?: boolean;
  readOnly?: boolean;
}

const CheckboxContainer = styled.label<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input<CheckboxProps>`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
`;

const VisibleCheckbox = styled.div<CheckboxProps>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  ${({ checked, theme: { colors }, disabled }) => css`
    background: ${checked ? colors.electricBlue : colors.white};
    border: 1px solid ${colors.coolGray4};

    ${disabled &&
    css`
      background-color: ${colors.coolGray4};
    `}
  `};

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  &:hover {
    cursor: pointer;
  }
`;

const Checkbox = ({
  className,
  name,
  onChange,
  checked,
  disabled,
  'data-test': dataTest,
  label,
  fullWidth,
  readOnly = false,
}: CheckboxProps): React.ReactElement => (
  <CheckboxContainer className={className} fullWidth={fullWidth}>
    <HiddenCheckbox
      type="checkbox"
      name={name}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      data-test={dataTest}
      readOnly={readOnly}
    />
    <VisibleCheckbox checked={checked} disabled={disabled}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </VisibleCheckbox>
    {label && (
      <Paragraph ml="12px" variant="text-small">
        {label}
      </Paragraph>
    )}
  </CheckboxContainer>
);

export default Checkbox;
