import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type UseLocalStorage<T> = [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>
];

const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): UseLocalStorage<T> => {
  const initializer = () => {
    try {
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue !== null) {
        return JSON.parse(localStorageValue);
      }

      if (initialValue) {
        localStorage.setItem(key, JSON.stringify(initialValue));
      }

      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  };

  const [state, setState] = useState<T | undefined>(() => initializer());

  const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
    (newState) => {
      try {
        if (typeof newState === 'undefined') return;

        localStorage.setItem(key, JSON.stringify(newState));
        setState(newState);
      } catch (error) {
        // If user is in private mode or has storage restriction
        // localStorage can throw. JSON.parse and JSON.stringify
        // can throw, too.
      }
    },
    [key]
  );

  return [state, set];
};

export default useLocalStorage;
