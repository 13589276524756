import { AddressInput } from './address-input';
import { Checkbox } from './checkbox';
import { CurrencyInput } from './currency-input';
import { DateInput } from './date-input';
import { EmailInput } from './email-input';
import { PercentInput } from './percent-input';
import { PhoneInput } from './phone-input';
import { PinInput } from './pin';
import { RadioGroup, RadioItem } from './radio';
import { StateSelect } from './state-select';
import { TextInput } from './text-input';
import { TextAreaInput } from './textarea';

export const Inputs = {
  Address: AddressInput,
  Text: TextInput,
  Currency: CurrencyInput,
  Phone: PhoneInput,
  Email: EmailInput,
  Percent: PercentInput,
  StateSelect,
  Checkbox,
  RadioGroup,
  RadioItem,
  Pin: PinInput,
  Date: DateInput,
  TextArea: TextAreaInput,
};
